import React, { useEffect, useState } from 'react';
import { StudentQuizStatement } from '../../../../../model';
import { Checkbox } from '@material-ui/core';
import "./DigitalStatements.scss";
import soundIcon from "../../../../../../assets/sound_icon.png";

interface DigitalStatementsProps {
    statements: StudentQuizStatement[];
    introQuestion: string;
    introQuestionMedia: string;
    indexPlayer: HTMLAudioElement;
    soundPlayer: HTMLAudioElement;
    handleStatementClick: (id: number) => void;
    
}

const DigitalStatements: React.FC<DigitalStatementsProps> = (props: DigitalStatementsProps) => {


    const [shouldUpdate, setshouldUpdate] = useState(false);

    const handleStatementClickIOC = (index: number) => {
        setshouldUpdate(!shouldUpdate);
        props.handleStatementClick(index + 1);
    } 

    const handleIntroQuestionSoundPlay = () => {
      if (props.introQuestionMedia !== '') {
        playTextSound(props.introQuestionMedia);
      }      
    }

    const handleStatementSoundPlay = (index: number, e, stopPropagation: boolean) => {
      if (props.statements[index].soundMedia && props.statements[index].soundMedia !== '') {
        playTextSound(props.statements[index].soundMedia);
      };

      if (stopPropagation) {
        e.stopPropagation();
      }
    }

    function playTextSound(mediaSrc: string) {
      props.soundPlayer.src = mediaSrc;
      props.soundPlayer.load();
      props.soundPlayer.play();
    }

    const renderStatement = (statement: StudentQuizStatement, index: number) => {
        return (
            <div key={index} className={statement.answer === 1 ? "DigitalStatement marked" : 'DigitalStatement'} onClick={() => handleStatementClickIOC(index)}>
                <div className='checkBoxDiv'>
                <Checkbox checked={statement.answer === 1}/>
                </div>
                <span className="icon" onClick={(e) => handleStatementSoundPlay(index, e, true)}><img alt="SOUND-ICON" src={soundIcon}/></span>
                <div className='textDiv' onClick={(e) => handleStatementSoundPlay(index, e, false)}>
                {statement.text}
                </div>
            </div>
        )
    }


    return (
        <div className='DigitalStatements'>
            <div className="IntroQuestion" onClick={handleIntroQuestionSoundPlay}><h1> <span className="icon"><img alt="SOUND-ICON" src={soundIcon}/></span>{props.introQuestion}</h1></div>
            {/*<div className='helperText'>Vælg det eller de korrekte svar</div>*/}    
            <div className='statementBox'>{props.statements.map((statement, index) => renderStatement(statement, index))}</div>
        </div>
    );
};

export default DigitalStatements;