import React, { useCallback, useEffect, useState } from "react";
import "./AdminNotificationView.scss"
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from '@mui/icons-material/Check';
import { TeoriLogo } from "../../../../globalComponents/TeoriLogo";
import { Button } from "@material-ui/core";
import { NotificationActionBar } from "./components/NotificationActionBar";
import { CustomDatePicker } from "./components/CustomDatePicker/CustomDatePicker";
import { useHistory } from "react-router-dom"
import { useAddStudentsNotificationMutation, useGetNotificationStudentsCountLazyQuery, useGetNotificationStudentsCountQuery } from "../../../../graphql/generated/graphql";

export type IAdminNotificationViewProps = {};

const AdminNotificationView: React.FC<IAdminNotificationViewProps> = ({}) => {
  const [studentsCount, setStudentsCount] = useState(0);
  const [sendStudentsCount, setSendStudentsCount] = useState(0);
  const [notificationSend, setNotificationSend] = useState(false);
  const [selectedNotificationType, setNotificationType] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const [schoolId, setSchoolId] = useState<number[]>([]);
  const [messageText, setMessageText] = useState("");
  const [fetchCount, { data: countData, loading: countLoading }] = useGetNotificationStudentsCountLazyQuery();
  const [addStudentsNotification] = useAddStudentsNotificationMutation();
  const [sendingData, setSendingData] = useState(false);
  const history = useHistory();

  const notificationTypes = [
    { label: 'All students', value: 0},
    { label: 'Driving school', value: 1}
  ];

  const refetch = () => {
    fetchCount({variables:{school_id: schoolId, language_id: selectedLanguage}});
  };

  useEffect(() => {
    fetchCount({variables:{school_id: schoolId, language_id: selectedLanguage}});
  }, []);

  useEffect(() => {
    if (countData && (countData.getNotificationStudentsCount || countData.getNotificationStudentsCount === 0)) {
      setStudentsCount(countData.getNotificationStudentsCount);
    }
  }, [countData]);

  useEffect(() => {
    fetchCount({variables:{school_id: schoolId, language_id: selectedLanguage}});
  }, [selectedLanguage, fetchCount]);

  const getButtonText = () => {
    return notificationSend ? "New notification" : (sendingData ? "Sending..." : "Send");
  }

  const handleNotificationChange = (e) => {
    setNotificationType(Number(e.target.value));
    if (e.target.value === '0'){
      setSchoolId([]);
      fetchCount({variables:{school_id: [], language_id: selectedLanguage}});
    }
  }

  const handleDateChange = (timeString: string) => {
    let date = new Date(Number(timeString) * 1000);
    let utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    setSelectedDate(new Date(utcDate));
  }

  const handleLanguageChange = (event) => {
    setSelectedLanguage(Number(event.target.value));
  }

  const handleSchoolChange = (e) => {
    let ids: number[] = [];
    let values = e.target.value.split(',');
    values.forEach(id => {
      ids.push(Number(id));
    });
    setSchoolId(ids);
  }

  const handleTextAreaChange = (e) => {
    setMessageText(e.target.value);
  }

  const handlClose = () => {
    history.push("/dashboard");
  }

  const isActionButtonDisabled = () => {
    return (!notificationSend && (messageText === ""
      || (selectedNotificationType === 1 && schoolId.length === 0)))
      || sendingData;
  }

  const resetToDefault = () => {
    setNotificationType(0);
    setSchoolId([]);
    setSelectedDate(new Date());
    setMessageText("");
    setSendStudentsCount(0);
    setSelectedLanguage(1);
  }

  const handleActionButtonClick = () => {
    if (notificationSend) {
      resetToDefault();
      setNotificationSend(false);
      return;
    }
    
    setSendingData(true);
    addStudentsNotification({variables: { 
      school_id: schoolId,
      language_id: selectedLanguage,
      expiry_date: selectedDate,
      message: messageText
    }}).then((data) => {
      if(data.data){
        setSendStudentsCount(data.data.addStudentsNotification);
      };
      setNotificationSend(true);
      setSendingData(false);
    })
  }

  const renderSchoolSelect = () => {
    return (
      <div className="secondarySelection">
        <div className="schoolSelect">
          <label>{"School ID"}</label>
          <input type="text" pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]" onChange={handleSchoolChange}></input>
        </div>
        <div className="calculate">
          <button onClick={refetch}>{"Calculate"}</button>
        </div>
      </div>
    );
  };

  const renderEmptySection = () => {
    return (
      <div className="secondarySelection">

      </div>
    );
  };

  const renderInteractionSide = () => {
    return (
    <div className="interactionSide">
      <div className="primerySelections">
        <div className="select">
          <label>{"Type"}</label>
          <select onChange={handleNotificationChange} value={selectedNotificationType}>
            {notificationTypes.map((type) => <option key={type.value} value={type.value}>{type.label}</option>)}
          </select>
        </div>
        <div className="dateDiv">
          <label>{"Expiration date (optional)"}</label>
          <CustomDatePicker value={String(selectedDate.getTime() / 1000)} onChange={handleDateChange}/>
        </div>
      </div>

      {selectedNotificationType > 0 ? renderSchoolSelect() : renderEmptySection()}

      <div className="messageArea">
        <div className="selection">
          <div className="label">
            <label>{"Message"}</label>
          </div>
          <div className="redioGroups">
            <input type="radio" value={1} checked={selectedLanguage === 1} onChange={handleLanguageChange}/>
            <label className="margin">DK</label>
            <input type="radio" value={2} checked={selectedLanguage === 2} onChange={handleLanguageChange}/>
            <label>UK</label>
          </div>
        </div>
        <textarea className="textarea" onChange={handleTextAreaChange}></textarea>
      </div>
      
    </div>
    )
  }

  const renderNotificationCompletionSide = () => {
    return (
      <div className="interactionSide">
        <h1 className="attention"><CheckIcon className="check"/> Send to {sendStudentsCount} {sendStudentsCount === 1 ? "studnet" : "students"}</h1>
      </div>
    )
  }

  return (
    <div className="pageNotification">
      <div className="logoBar">
        <TeoriLogo />
        <Button className="closeButton" onClick={handlClose}>
          <ClearIcon />
        </Button>
      </div>
      <div className="notification">
        <div className="informationSide">
          <h2>{"Notificationsystem"}</h2>
        </div>
        {notificationSend ? renderNotificationCompletionSide() : renderInteractionSide() }
      </div>
      <NotificationActionBar 
        studnetsCount={studentsCount}
        buttonText={getButtonText()} 
        onClick={handleActionButtonClick}
        buttonDisabled={isActionButtonDisabled()}
        showStudentsCount={!notificationSend}/>
    </div>
  )
};

export { AdminNotificationView };