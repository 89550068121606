import React from "react";
import "./NotificationActionBar.scss"
import whiteArrow from "../../../../../assets/white_Arrow.png";

export type NotificationActionBarProps = {
  studnetsCount: number,
  buttonDisabled: boolean,
  buttonText: string,
  onClick: any,
  showStudentsCount: boolean
};

const NotificationActionBar: React.FC<NotificationActionBarProps> = (props: NotificationActionBarProps) => {
  const handleClick = () => {
    props.onClick();
  }

  const renderStudnetCount = () => {
    return (
      <span className="studentsCount">
        {props.studnetsCount} {"students"}
      </span>
    )
  }

  return (
    <div className="bar">
      { props.showStudentsCount ? renderStudnetCount() : null }
      <button disabled={props.buttonDisabled} className="sendButton" onClick={handleClick}><span className="text">{props.buttonText}</span>
        <span className="icon"><img alt="FINISH-ICON" src={whiteArrow}/></span></button>
    </div>
  )
}

export {NotificationActionBar}