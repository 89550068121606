import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import 'react-calendar/dist/Calendar.css';
import { Button } from '@mui/material';
import "./CustomDatePicker.scss";

export type ICustomDatePickerProps = {
    value: string;
    onChange: any;
}

const CustomDatePicker: React.FC<ICustomDatePickerProps> = (props: ICustomDatePickerProps) => {

    const [isExpanded, setisExpanded] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
          setisExpanded(false);
        }
      };
  
      document.addEventListener("click", handleClick, true);
  
      return () => {
        document.removeEventListener("click", handleClick, true);
      };
    }, [modalRef, setisExpanded]);

    const handleChangeCalendar = (value): void => {
      const date: Date = value;
      console.log(date.toDateString());
      props.onChange(date.getTime() / 1000);
      setisExpanded(false)
    };

    function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    }
  
    function formatDate(timeString) {
      const date = new Date(timeString * 1000);
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/");
    }


    const renderCalendar = () => {
        return (
          <div ref={modalRef} className="calendarBox">
            <Calendar
              value={new Date(Number(props.value) * 1000)}
              onChange={handleChangeCalendar}
            />
          </div>
        );
      };
    return (
        <div className='datePicker'>
          <input value={formatDate(props.value)} onClick={() => setisExpanded(true)} className="dateInput" readOnly></input>
            {isExpanded ? 
            renderCalendar()
             : null}
        </div>
    );
}

export { CustomDatePicker };